<template>
  <div>
    <vs-popup :active.sync="popUpCreate" title="Confirmation">
        <p align="center">
            Are you are sure you want to continue without a Sage ID ?
        </p>
        <br />
        <div class="flex justify-end gap-2">
            <vs-button size="lg" color="primary" @click="saveSupplier">
                Yes, Continue!
            </vs-button>
            <vs-button size="lg" color="danger" @click="popUpCreate = false">Cancel</vs-button>
        </div>
    </vs-popup>
    <vs-form autocomplete="off">
      <div class="custom-form pt-4">
        <div class="py-4">
          <h6 style="font-weight:700;">Supplier details</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="4">
            <vs-input :success="!errors.first('basic.name') && supplierData.name != ''" val-icon-success="done"
              val-icon-danger="error" :danger="errors.first('basic.name') ? true : false" v-validate="'required'"
              name="name" data-vv-as="Supplier name" label="Supplier Name" class="custom-input w-full"
              data-vv-scope="basic" :danger-text="errors.first('basic.name')" v-model="supplierData.name" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="4">
            <vs-input v-validate="'required'" name="email" data-vv-as="Email" label="Supplier orders email addresses"
              class="custom-input w-full" data-vv-scope="basic" :danger-text="errors.first('basic.email')"
              v-model="supplierData.email" val-icon-danger="error" :danger="errors.first('basic.email') ? true : false"
              :success="!errors.first('basic.email') && supplierData.name != ''" val-icon-success="done" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="4">
            <vs-input name="contactNumber" data-vv-as="Contact number" label="Supplier contact # (optional)"
              class="custom-input w-full" data-vv-scope="basic" :danger-text="errors.first('basic.contactNumber')"
              v-model="supplierData.contactNumber" val-icon-danger="error"
              :danger="errors.first('basic.contactNumber') ? true : false" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="4">
            <div class="sageButton" ref="sageButton">
                <vs-input state="success"  name="sageSupplierId" data-vv-as="Sage Supplier id" label="Sage Id"
                class="custom-input w-full" data-vv-scope="basic" :danger-text="sageError"
                val-icon-success="done"
                loading
                val-icon-danger="error"
                :success-text="sageSuccess"
                :success="sageSuccess ? true : false"
                v-model="supplierData.sageSupplierId" 
                @input="validateSageVendor"
                :danger="sageError ? true : false" />
            </div>
            
          </vs-col>
        </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form py-4">
        <div class="py-4">
          <h6 style="font-weight:700;">Delivery fee</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="4">
            <vs-input class="custom-input w-full" :success="
              !errors.first('basic.deliveryFee') &&
              supplierData.deliveryDetails.deliveryFee != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.deliveryFee') ? true : false" v-validate="'required|decimal:3'"
              name="deliveryFee" data-vv-as="Delivery fee" label="Default delivery fee" data-vv-scope="basic"
              :danger-text="errors.first('basic.deliveryFee')" v-model="supplierData.deliveryDetails.deliveryFee" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" v-if="isVisible">
          <vs-col vs-w="4">
            <vs-button :disabled="isDisabled" type="flat" icon-pack="feather" icon="icon-plus" class="p-0"
              @click="showModal = !showModal">Add
              shipping fee rule</vs-button>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" v-if="!showAddBtn">
          <vs-col vs-w="6">
            <div class="custom-rule p-4">
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
                <vs-col vs-w="12">
                  <vs-input :success="true" val-icon-success="done"
                    label="If at checkout the cart $ value of this suppliers products is equal to or greater than:"
                    class="custom-input w-full mb-1" v-model="supplierData.rule.minimumOrder" readonly="readonly" />
                </vs-col>
              </vs-row>
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
                <vs-col vs-w="12">
                  <vs-input :success="true" val-icon-success="done" label="Update shipping fee for supplier to be"
                    class="custom-input w-full mb-1" v-model="supplierData.rule.discountedPrice" readonly="readonly" />
                </vs-col>
              </vs-row>
              <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
                <vs-col vs-w="6">
                  <vs-button size="small" type="border" @click="edit()">Edit shipping rule</vs-button>
                </vs-col>
                <vs-col vs-w="6" style="text-align:right;">
                  <vs-button size="small" type="border" color="danger" @click="remove()">Remove</vs-button>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form py-4">
        <div class="py-4 pb-6">
          <h6 style="font-weight:700;">Supplier access</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="12">
            <vs-checkbox v-model="supplierData.isInstant">
              <p style="font-size:12px;font-weight:600;color:black;">Instant access</p>
            </vs-checkbox>
            <div class="pl-8">
              <p class="pl-1" style="font-size:11px;color:#000000;">Active - All store accounts have instant access to
                this
                supplier
              </p>
              <p class="pl-1" style="font-size:11px;color:#000000;">Inactive - This supplier must be toggled on for each
                clinic
                manually through clinic settings
              </p>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form py-4">
        <div class="py-4 pb-6">
          <h6 style="font-weight:700;">Bundle Supplier</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="12">
            <div class="flex">
              <vs-switch v-model="supplierData.isBundledSupplier" />
              <div class="pl-1 pt-1">
                <span style="font-size: 12px; font-weight: 600; color: black;">Is Bundle Supplier</span>
                <p class="pl-1" style="font-size:11px;color:#000000;">Active - this supplier will be used as a placeholder
                  for bundle items supplier
                </p>
                <p class="pl-1" style="font-size:11px;color:#000000;">Inactive - This supplier is a regular supplier
                </p>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form py-4">
        <div class="py-4 pb-6">
          <h6 style="font-weight:700;">Supplier Onboard Link</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="4">
            <vs-input label="Onboard Link" name="supplierOnboardingLink" v-model="supplierData.supplierOnboardingLink" class="custom-input w-full"/>
          </vs-col>
        </vs-row>
      </div>

      <vs-divider></vs-divider>
      <div class="custom-form py-4 border-0 pb-24">
        <div class="py-4 pb-6">
          <h6 style="font-weight:700;">Supplier Visibility</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="12">
            <vs-radio v-model="supplierData.privateStatus" :vs-value="false">
              <p style="font-size:12px;font-weight:600;color:black;">Public</p>
            </vs-radio>
            <div class="pl-4">
              <p class="pl-3" style="font-size:11px;color:#000000;">Default Visibility. This will allow all clinics to view this supplier.
              </p>
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-5">
          <vs-col vs-w="12">
            <vs-radio v-model="supplierData.privateStatus" :vs-value="true">
              <p style="font-size:12px;font-weight:600;color:black;">Private</p>
            </vs-radio>
            <div class="pl-4">
              <p class="pl-3" style="font-size:11px;color:#000000;">This will only allow specific users associated to this supplier to view it.
              </p>
            </div>
          </vs-col>
          <vs-col vs-w="4" v-if="supplierData.privateStatus" style="height:350px;margin-top:5px;">
            <label style="font-size:12px;padding:5px 0;display:block;">Assign Clinics</label>
            <v-select multiple :options="clinicList" :closeOnSelect="false" :clearable="false" v-validate="'required'"
              name="privateAssignedClinics" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="assignedClinics" />
            <span class="text-danger" style="font-size: 0.85em">{{
                errors.first("assignedClinicsIsError")
            }}</span>
          </vs-col>
        </vs-row>
      </div>
    </vs-form>
    <vs-popup class="holamundo shipping-fee-modal" title="Shipping fee rule" :active.sync="showModal">
      <vs-form autocomplete="off">
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="12">
            <vs-input :success="!errors.first('rule.minOrder') && supplierData.name != ''" val-icon-success="done"
              val-icon-danger="error" :danger="errors.first('rule.minOrder') ? true : false"
              v-validate="'required|decimal:3|min_value:1'" name="minOrder" data-vv-as="Minimum order"
              label="If at checkout the cart $ value of this suppliers products is equal to or greater than:"
              class="custom-input w-full" data-vv-scope="rule" :danger-text="errors.first('rule.minOrder')"
              v-model="supplierData.rule.minimumOrder" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="12">
            <vs-input :success="!errors.first('rule.discount') && supplierData.name != ''" val-icon-success="done"
              val-icon-danger="error" :danger="errors.first('rule.discount') ? true : false"
              v-validate="'required|decimal:3'" name="discount" data-vv-as="Discount"
              label="Update shipping fee for supplier to be" class="custom-input w-full" data-vv-scope="rule"
              :danger-text="errors.first('rule.discount')" v-model="supplierData.rule.discountedPrice" />
          </vs-col>
        </vs-row>
      </vs-form>
      <vs-divider></vs-divider>
      <vs-row vs-align="space-around" vs-type="flex" vs-w="12">
        <vs-col vs-w="6">
          <vs-button style="padding:4px 15px;" @click="cancelRule()" type="border">Cancel</vs-button>
        </vs-col>
        <vs-col vs-w="6" style="display:block;text-align:right">
          <vs-button style="padding:5px 15px;margin-right:5px;" @click="saveRule()">Apply</vs-button>
        </vs-col>
      </vs-row>


    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SupplierTrackingList from "./SupplierTrackingList";
import vSelect from "vue-select";
import axios from "@/axios.js"

export default {
  name: "SupplierDetails",
  components: {
    SupplierTrackingList,
    vSelect
  },
  props: ["supplierId", "onSave", "createOrUpdateSupplier"],
  data() {
    return {
      popUpCreate:false,
      id: this.supplierId,
      isValidatingSupplier:true,
      sageError:'',
      sageSuccess:'',
      timeout:'',
      supplierData: {
        name: "",
        email: "",
        contactNumber: "",
        deliveryDetails: {
          deliveryFee: 0
        },
        isInstant: false,
        privateStatus: false,
        privateAssignedClinics: [],
        rule: {
          minimumOrder: 0,
          discountedPrice: 0
        },
        sageSupplierId:'',
        supplierOnboardingLink: "",
      },
      assignedClinics: [],
      clinicList: [],
      showModal: false,
      showAddBtn: true,
      assignedClinicsIsError: false
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    isDisabled() {
      if (this.supplierData.deliveryDetails.deliveryFee <= 0 && this.supplierData.rule.minimumOrder <= 0) {
        return true;
      }
      return false;
    },
    isVisible() {
      if (this.supplierData.rule.minimumOrder <= 0) {
        return true;
      }
      return false;
    }
  },
  mounted() { },
  watch: {
    onSave: function (isSave) {
      if (isSave) {
        this.saveDetails()
      }
    },
    "supplierData.privateStatus": function (curr, prev) {
      if (curr && !this.clinicList.length) {
        this.getAllClinics();
      }
    }
  },
  methods: {
    ...mapActions("supplier", ["getSupplierDetails"]),
    ...mapActions("clinic", ["fetchAllClinic"]),

    async fetchSupplierDetails(id) {
      this.$vs.loading();
      await this.getSupplierDetails(id).then(res => {
        this.supplierData = res.data.data;
        this.supplierData = {
          ...this.supplierData,
          privateStatus: this.supplierData.privateStatus ? this.supplierData.privateStatus : false,
          rule: this.supplierData.rule ? { minimumOrder: this.supplierData.rule.minimumOrder / 100, discountedPrice: this.supplierData.rule.discountedPrice / 100 } : { minimumOrder: 0, discountedPrice: 0 },
          isInstant: this.supplierData.isInstant ? this.supplierData.isInstant : false,
          email: this.mapEmail(this.supplierData),
          deliveryDetails: {
            deliveryFee: this.getDeliveryFee(this.supplierData)
          }
        }
        if (this.supplierData.rule.minimumOrder > 0) {
          this.showAddBtn = false;
        }

        this.getSageSupplierInfo()
        
        this.$vs.loading.close();
      });
    },
    mapEmail(data) {
      if (data.email) {
        return data.email.map(email => email.trim()).join(",")
      }
      return ""
    },
    getDeliveryFee(data) {
      if (data.deliveryDetails && data.deliveryDetails.deliveryFee) {
        return data.deliveryDetails.deliveryFee / 100
      }
      return 0;
    },
    getAllClinics() {
      this.$vs.loading();
      this.fetchAllClinic()
        .then(async res => {
          this.$vs.loading.close();
          this.clinicList = [...res.data.data].map(({ clinicName: label, id: value }) => ({ label, value }));
          this.supplierData.privateAssignedClinics.forEach((item) => {
            let clinic = this.clinicList.find(
              (clinicObj) => clinicObj.value === item
            );
            this.assignedClinics.push(clinic);
          });
          this.$vs.loading.close();
        })
    },
    async saveRule() {
      const validRule = await this.$validator.validateAll("rule");
      if (validRule) {
        this.showModal = false;
        this.showAddBtn = false;
      }
    },
    cancelRule() {
      this.showModal = false;
      if (this.showAddBtn) {
        this.supplierData.rule = {
          minimumOrder: 0,
          discountedPrice: 0
        }
      }
    },
    edit() {
      this.showModal = true;
    },
    remove() {
      this.supplierData.rule = {
        minimumOrder: 0,
        discountedPrice: 0
      }
      this.showAddBtn = true;
    },
    async saveDetails() {
      /* eslint-disable */
      this.isValid = await this.$validator.validateAll("basic");
      // this.isValid = await this.$refs.stepTwoRef.$validator.validate();

      if (
        this.supplierData.privateStatus && !this.assignedClinics.length
      ) {
        this.isValid = false;
        this.errors.add({
          field: "assignedClinicsIsError",
          msg: "Clinic/s must be assigned if supplier is private",
        });
      }

      if (!this.isValid || this.sageError) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      } else {
        if (!this.supplierData.sageSupplierId) {
            this.popUpCreate = true
        } else {
            this.saveSupplier()
        }

        this.errors.remove("assignedClinicsIsError");
      }

      // 
    },
    saveSupplier() {
        const data = {
          ...this.supplierData,
          deliveryDetails: {
            deliveryFee: parseFloat(this.supplierData.deliveryDetails.deliveryFee) * 100
          },
          email: this.supplierData.email.split(","),
          privateAssignedClinics: this.assignedClinics.map(({ value: _id }) => ({ _id })),
          rule: {
            minimumOrder: parseFloat(this.supplierData.rule.minimumOrder) * 100,
            discountedPrice: parseFloat(this.supplierData.rule.discountedPrice) * 100
          },
          trackingOptions: []
        }
        this.popUpCreate = false
        this.$emit("createOrUpdateSupplier", data);
    },
    async validateSageVendor() {
        if (this.timeout) 
            clearTimeout(this.timeout); 

        this.timeout = setTimeout(async () => {
            this.getSageSupplierInfo()
        }, 1000); // delay
    },
    async getSageSupplierInfo() {
        try {
            if (!this.supplierData.sageSupplierId) {
                this.sageSuccess = ''
                this.sageError = ''
                return
            }

            this.$vs.loading();

            const {data} = await axios.get(`/api/v1/store/suppliers/sageDetails/${this.supplierData.sageSupplierId}`)
            
            if (data && data.data && data.data.NAME) {
                this.sageSuccess = `Supplier ID ${this.supplierData.sageSupplierId} corresponds to an entity named ${data ? data.data.NAME : ''} on the Sage platform.`
                this.sageError = ''
            } else {
                this.sageSuccess = ''
                this.sageError = `The supplier with ID ${this.supplierData.sageSupplierId} does not exist on the Sage platform.`
            }
        } catch {
            this.sageSuccess = ''
            this.sageError = `The supplier with ID ${this.supplierData.sageSupplierId} does not exist on the Sage platform.`
        } finally {
            this.$vs.loading.close();
        }
    }
  },
 
  async created() {
    this.id = this.supplierId;
    if (this.id) {
      await this.fetchSupplierDetails(this.id);
    }
  },
};
</script>

<style>
.shipping-fee-modal .vs-popup--header .vs-popup--title h3 {
  display: block !important;
}

.shipping-fee-modal .vs-popup .vs-popup--content {
  padding: 1rem !important;
  margin: 5px 5px 5px !important;
}

.custom-input {
  margin-bottom: 20px;
}

.custom-input label {
  display: block;
  padding: 5px 0;
}

.stretch-width {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
}

.custom-rule {
  border: solid 1px #28c76f;
  border-radius: 5px;
  background-color: #f0f0f0;
}
</style>
